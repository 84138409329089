$primary-color: #ffd700;

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    max-height: 90%;
  }

  h1 {
    // color: #fff;
    color: aliceblue;
    font-size: 56px;
    line-height: 50px;
    margin: 0;
    font-family: 'La Belle Aurore';
    // font-family: 'Coolvetica';
    font-weight: 200;

    // &:before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: $primary-color;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -40px;
    //   left: -15px;
    //   animation: fadeIn 1s 1.8s backwards;
    //   opacity: 0.6;
    // }

    // &:after {
    //   content: '<h1/>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: $primary-color;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: 30px;
    //   left: -15px;
    //   animation: fadeIn 1s 1.8s backwards;
    //   opacity: 0.6;
    // }

    img {
      margin-left: 20px;
      // padding-top: 10px;
      opacity: 0;
      width: 18%;
      height: auto;
      animation-direction: normal;
      // animation: fadeInLeft 0.8s ease both;
      // animation-delay: 1s;
      animation: bounceIn;
      animation-duration: 2.5s;
      animation-delay: 2.5s;
      animation-fill-mode: forwards;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: aliceblue;
    font-family: 'La Belle Aurore' !important;
    opacity: 0.5;
    box-shadow: 1px 1px 2px #fff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 13px 18px;
    border: 1px solid $primary-color;
    margin-top: 50px;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    position: relative;
    left: -14px;
    @include mobile() {
      left: 0px;
    }

    &:hover {
      background: $primary-color;
      color: #333;
      opacity: 1;
    }
  }
  &.wrapper-class:hover {
    color: #333 !important;
  }
}

.flat-button-wrapper {
  display: flex;
  align-items: center;
}

.spinner-container {
  margin-left: 8px;
  display: inline;
  position: relative;
  bottom: 6px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height to match your layout */
}

.timeline {
  // @include mobile() {
  //   display: none;
  // }
  position: relative;
  left: 300px;
  top: 200px;
  height: 100vh; /* Adjust the height to match your layout */
  color: $primary-color;
  opacity: 0.6;
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1:before,
  .home-page h1:after {
    display: none;
  }

  .home-page .text-zone {
    position: initial;
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
  }

  .home-page .flat-button {
    float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }

  .logo-container {
    position: relative;
    width: 100px;
    height: auto;
    top: 50px;
    right: 0;
    box-sizing: border-box;
    margin: auto;
    left: 0;
  }

  .logo-container svg {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
  }
}

@media only screen and (max-width: 700px) {
  .home-page .flat-button {
    float: none;
    display: block;
    margin: 35px auto 0 auto;
    width: 50%;
    text-align: center;
  }

  .home-page {
    text-align: center;
  }

  .home-page .text-zone h1 {
    font-size: 35px;
    line-height: 1.3;
    margin-top: 15vh;
  }

  .home-page .text-zone h2 {
    font-size: 15px;
    line-height: 1.5;
  }

  .home-page .text-zone img {
    width: 25%;
    margin-left: 13px;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .timeline {
    position: relative;
    left: 0;
    top: 100px;
    height: 80vh; /* Adjust the height to match your layout */
    color: $primary-color;
  }
}
