.container.about-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;
    letter-spacing: 1px;

    // &:before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: -10px;
    //   opacity: 0.6;
    //   line-height: 18px;
    // }

    // &:after {
    //   content: '<h1/>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: -30px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }

  p {
    font-size: 13px;
    color: aliceblue;
    font-family: sans-serif;
    font-weight: 400;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }

  .face1 {
    transform: translateZ(100px);
    color: #F9A600;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #76ABFF;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #C76496;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ED4F4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #EFD81D;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #EC4D28;
  }
  /*
  .face7 {
    box-shadow: none;
    background: none;
    border: none;
  }
  */
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

/* Mobile version */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Adjust the height to match your layout */
  margin-left: 30px;
}

@media screen and (max-width: 700px) {
  .loader-container {
    position: fixed; /* Set to fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 900px) {
  .container.about-page {
    h1 {
      box-sizing: border-box;
      width: 80%;
    }

    .text-zone {
      width: 83%;
      margin-top: 2em;

      p {
        text-align: center;
        font-size: 11px;
        font-weight: 300;
        margin-top: 1.5rem;
        border-radius: 3.33% 3.33%;
        border-bottom: 3px solid rgba(255,255,255,0.2); /* Add this line to add lines between paragraphs */
        padding-bottom: 20px; /* Optional: add padding to separate the lines from the text */        
      }
    }
  }

  .cubespinner {
    // transform-origin: 50px 50px 0;
    // margin-left: calc(50% - 50px);
    display: none;
  }
}
