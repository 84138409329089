html {
  font-size: 62.5%;
}

body {
  margin: 0;
  /* used as a default font throughout the application */
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #03254E; */
  /* background-color: #181818; */
  /* background: #323d32; */
  overflow: hidden;
  display: block;
}

/** Add a new opaque color (content) layer over the img */
body::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    rgba(0, 0, 100, 0.9),
    rgba(27, 0, 250, 0.6)
  ), url('assets/images/dark_woods.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  /* filter:contrast(5) */
}

h1 {
  font-family: 'AROneSans', sans-serif;
}

input {
  touch-action: none !important;
}

/* button {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  margin: 0 10px;
  width: 200px;
  padding: 10px 0;
  box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
  transition: 0.4s;
}

button:hover {
  color: white;
  box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
  background-color: rgba(104, 85, 224, 1);
}

.log {
  color: rgb(104, 85, 224);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(104, 85, 224, 1);
} */

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}