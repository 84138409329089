.dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    align-items: center;
    color: whitesmoke;
  
  
    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      text-align: center;
      animation: rubberBand 1s;
    }

    .email {
        color: aliceblue;
        font-size: 2rem;
        width: 300px;
        margin-top: 200px;
        // text-align: center;
        animation: rubberBand 1s;
      } 

      input, textarea {
        width: 300px;
        padding: 10px;
      }

      input::file-selector-button {
        position: relative;
        right: 10px;
      }
  
    // .log {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     text-align: center;
    //     color: #ffd700;
    //     font-size: 1rem;
    //     font-weight: 400;
    //     letter-spacing: 3px;
    //     text-decoration: none;
    //     padding: 5px;
    //     border: 1px solid #ffd700;
    //     border-radius: 4px;
    //     background: 0 0;
    //     text-transform: uppercase;
    //     text-align: center;
    //     margin: 15px;
  
    //   &:hover {
    //     background-color: #ffd700;
    //     color: #032c43;
    //     font-weight: 600;
    //   }
    // }
  }
  