.container.portfolio-page {
    // padding-left: 20px;
    // padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    blockquote {
        color: rgb(184,136,136, 0.8);
        font-weight: 400;
        font-size: 2em;
        margin: 75px auto 40px auto;
        text-align: center;
        font-family: 'La Belle Aurore'!important;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;  // 4 images in a row
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 10px;
            margin-top: 0;
            color: #fff;
            text-shadow: 1px 1px 2px #444;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            text-shadow: 1px 1px 2px #444;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateX(3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.55;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height to match your layout */
    margin-left: 30px;
  }
  
  @media screen and (max-width: 700px) {
    .loader-container {
      position: fixed; /* Set to fixed positioning */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
@media only screen and (max-width: 900px) {
    .container.portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;  

        .image-box {
            height: 400px;
            min-width: calc(100% - 10px); // only two columns on mobile
            left: 5px;

            .title {
                font-size: 18px;
                line-height: 1;
            }

            .description {
                font-size: 12px;
            }
        }
        h1.page-title {
            margin-left: 20px!important;
            margin-top: 20px!important;
          }
    
          blockquote {
            font-weight: 400;
        }        
    }
}