/* Desktop version */

.contact-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;
    letter-spacing: 1px;

    // &:before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: -10px;
    //   opacity: 0.6;
    //   line-height: 18px;
    // }

    // &:after {
    //   content: '<h1/>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: -30px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;
  & .popup-link {
    color: initial;
    text-decoration: none;
  }
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

.info-map {
  position: absolute;
  background: #000;
  top: 50px;
  right: 30%;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;

    span {
      color: #ffd700;
    }
  }
  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}

/* Mobile version */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Adjust the height to match your layout */
  margin-left: 30px;
}

@media screen and (max-width: 700px) {
  .loader-container {
    position: fixed; /* Set to fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .contact-page {
    h1 {
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center;
      left: 0;
      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    .text-zone {
      position: static;
      transform: none;
      width: 90%;
      margin-top: 20px;
      display: block;
      margin-left: 20px;
      // overflow: hidden;
    }

    p {
      text-align: center;
      font-size: 16px;
    }
  }

  .contact-form {
     li {
    width: 100%;
    margin: 0 auto;
    float: none;
    clear: both;
  }

  li.half {
    width: 90%;
    margin-left: 0;
    float: none;
  }
}

  .map-wrap {
    width: 90%;
    height: 400px;
    margin-top: 30px;
    float: none;
    margin-left: 20px;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
  }

  .info-map {
    position: static;
    width: 90%;
    margin-top: 20px;
    text-align: center;
    opacity: 1;
    animation: none;
    margin-left: 20px;
    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  .flat-button {
    margin: 0 auto;
    margin-top: 20px;
    display: block;
    float: none;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
  }
}
