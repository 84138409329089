$primary-color: #ffd700;

@import 'animate.css';
// @import '~loaders.css/src/animations/ball-scale-multiple.scss';
@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2'),
    url('./assets/fonts/LaBelleAurore.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2'),
    url('./assets/fonts/CoolveticaRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AROneSans';
  src: url('./assets/fonts/AROneSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// html {
//   background-image: '../../assets/images/leaves.png';
// }

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

// .loader-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh; /* Adjust the height to match your layout */
//   margin-left: 30px;
// }

// @media screen and (max-width: 700px) {
//   .loader-container {
//     position: fixed; /* Set to fixed positioning */
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }