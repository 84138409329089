@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
// .container.skills-page {
//   display: flex;
//   flex-direction: column;
//   align-items: center; /* Center the content horizontally */
//   justify-content: center; /* Center the content vertically */
//   height: 100vh; /* Adjust the height to your needs */
// }

.skill {
  blockquote {
    width: 80%;
    color: rgb(184,136,136, 0.8);
    font-size: 1.75em;
    margin: 6rem;
    padding-left: 18rem;
    padding-bottom: 5rem;
    text-align: center;
    font-family: 'La Belle Aurore'!important;
    @include mobile {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}   
  & .skill-bx {
    width: 100%;
    text-align: center;
    // width: 70%;
    // margin-top: 25rem;
    transform: translateY(-15%);    
    height: 70vh;

    @include mobile {
      // margin: 2rem;
      margin-left: 1rem;
      height: 30vh;
      transform: translateY(20%);   
      
    } 

    & h3 {
      font-size: 18px;
      color: #fff;
      opacity: 0.6;
 
    }
    & p {
      color: #b88888;
      font: 16px 'Helvetica Neue', sans-serif;
      opacity: 0.8;
      margin-bottom: 3em;
    }
  }
  &-slider {
    margin: 0 auto;
    position: relative;

    & .item img {
      margin-bottom: 15px;
      
    }
  }
  .cap {
    text-align: right;
    font-family: 'La Belle Aurore';
    font-size: 1.5rem;
    color: rgba(250,250,250,0.3);
    height: 100px;
  }  
}

.background-image {display:none}

// .react-multiple-carousel__arrow {
//   // min-width: 0;
//   &--left {
//     left: 0;
//     bottom: 50%;
//   }
//   &--right {
//     right: 0;
//     bottom: 50%;
//   }
// }

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height to match your layout */
  margin-left: 30px;
}

@media screen and (max-width: 700px) {
  .loader-container {
    position: fixed; /* Set to fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

@media screen and (max-width: 900px) {
  .skill {
    & .skill-bx {
      width: 95%;
      text-align: center;
      position: relative;
      left: 0;
      top: 0;
      p {
        font-size: 2rem;
        // opacity: 0.5;
        font-weight: 400;
        padding-bottom: 10px;
      }
      
    }
  }
  .container.skills-page .text-zone {
    margin: 1em;
  }
}
